import React from 'react'
import { graphql } from 'gatsby'

import Layout from 'components/layout'
import PageHeader from 'components/page-header'
import ArticleTile from 'components/article-tile'

const _groupBy = require('lodash.groupby')
const _uniq = require('lodash.uniq')

const Blog = ({ data }) => {

  let articlesByYear = _groupBy(data.articles.edges, article => article.node.frontmatter.date.slice(-4))
  let articleYears = _uniq(data.articles.edges.map(article => article.node.frontmatter.date.slice(-4)).sort().reverse())
  
  return (
    <Layout>
      <PageHeader 
        title="Blog Posts" 
        sizes={data.headerImage.childImageSharp.fluid} 
      />
        
      <div className="l-full-page">
        {articleYears.map(year => (
          <React.Fragment key={year}>
            <h2>{year}</h2>
            <div className="articles">
              {articlesByYear[year].map(article => (
                <ArticleTile article={article.node} key={article.node.fields.slug} />
              ))}
            </div>
          </React.Fragment>
        ))}
      </div>
    </Layout>
  )
}

export const query = graphql`
  query {
    articles: allMarkdownRemark(
      filter: { 
        fields: { 
          type: { eq: "articles"}
        }
      }, 
      sort: {fields: [frontmatter___date], order: DESC}
    ) {
      edges {
        node {
          fields {
            type
            slug
          }
          frontmatter {
            title
            date(formatString: "MMM DD, YYYY")
            author
            category
            tags
            image {
              childImageSharp {
                fluid(maxWidth: 800) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            
          }
        }
      }
    }
    headerImage: file(relativePath: { eq: "blog-header.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1920) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`

export default Blog
